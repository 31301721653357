import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Modal } from "antd";
import { 
  CloudServerOutlined, 
  MobileOutlined, 
  RocketOutlined 
} from "@ant-design/icons";

interface ServiceCardProps {
  bgimage: string;
}

const ServicesSection = styled.section`
  padding: 100px 0;
  background: linear-gradient(
    135deg,
    #f8f9fa 0%,
    #edf6f9 35%,
    #e8f4f8 100%
  );
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  color: #023e8a;
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #00b4d8, #48cae4);
    border-radius: 2px;
  }
`;

const SectionSubtitle = styled.p`
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 4rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled(motion.div)<ServiceCardProps>`
  background: linear-gradient(
    rgba(255, 255, 255, 0.85),
    rgba(255, 255, 255, 0.90)
  ), url(${props => props.bgimage});
  background-size: cover;
  background-position: center;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.08);
    
    &::before {
      opacity: 1;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 180, 216, 0.1),
      rgba(2, 62, 138, 0.1)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #00b4d8;
  margin-bottom: 1.5rem;
  position: relative;
  
  .anticon {
    background: linear-gradient(135deg, #e6f8fb, #f0f9ff);
    padding: 15px;
    border-radius: 12px;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.4rem;
  color: #023e8a;
  margin-bottom: 1rem;
  font-weight: 500;
  position: relative;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.8);
`;

const ServiceDescription = styled.p`
  color: #333;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  position: relative;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
`;

const LearnMore = styled.button`
  color: #00b4d8;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  
  &:hover {
    color: #0077b6;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  
  h4 {
    color: #023e8a;
    margin: 20px 0 10px;
    font-size: 1.2rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
    
    li {
      margin: 10px 0;
      padding-left: 20px;
      position: relative;
      
      &::before {
        content: "•";
        color: #00b4d8;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const services = [
  {
    icon: <CloudServerOutlined />,
    title: "云服务解决方案",
    description: "提供安全、可靠、高效的云计算服务，助力企业数字化转型，实现业务快速增长。",
    bgImage: "http://oss.opsoul.com/case1.jpeg-jpg",
    details: {
      features: [
        "弹性计算资源，按需扩展",
        "高可用性和容灾备份",
        "安全防护和监控",
        "成本优化和资源管理"
      ],
      benefits: [
        "降低IT基础设施成本",
        "提高系统可靠性",
        "加快业务响应速度",
        "简化运维管理"
      ]
    }
  },
  {
    icon: <MobileOutlined />,
    title: "移动应用开发",
    description: "专业的移动应用开发团队，为您打造功能强大、体验优秀的 iOS 和 Android 应用。",
    bgImage: "http://oss.opsoul.com/case2.jpeg-jpg",
    details: {
      features: [
        "原生应用开发",
        "跨平台解决方案",
        "UI/UX设计",
        "性能优化"
      ],
      benefits: [
        "提升用户体验",
        "扩大市场覆盖",
        "增强品牌形象",
        "提高用户粘性"
      ]
    }
  },
  {
    icon: <RocketOutlined />,
    title: "数字化转型",
    description: "帮助企业实现数字化转型，提升运营效率，增强市场竞争力。",
    bgImage: "http://oss.opsoul.com/case3.jpeg-jpg",
    details: {
      features: [
        "业务流程数字化",
        "数据分析和决策",
        "智能化升级",
        "全渠道整合"
      ],
      benefits: [
        "提升运营效率",
        "降低运营成本",
        "优化决策流程",
        "提高市场竞争力"
      ]
    }
  }
];

const Services: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<typeof services[0] | null>(null);

  const handleLearnMore = (service: typeof services[0]) => {
    setSelectedService(service);
    setModalVisible(true);
  };

  return (
    <ServicesSection>
      <Container>
        <SectionTitle>我们的服务</SectionTitle>
        <SectionSubtitle>
          为企业提供全方位的技术解决方案，助力企业实现数字化转型
        </SectionSubtitle>
        <ServicesGrid>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              bgimage={service.bgImage}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <IconWrapper>{service.icon}</IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <LearnMore onClick={() => handleLearnMore(service)}>
                了解更多
                <span>→</span>
              </LearnMore>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>

      <Modal
        title={selectedService?.title}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={600}
      >
        {selectedService && (
          <ModalContent>
            <p>{selectedService.description}</p>
            <h4>核心功能</h4>
            <ul>
              {selectedService.details.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <h4>服务优势</h4>
            <ul>
              {selectedService.details.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </ModalContent>
        )}
      </Modal>
    </ServicesSection>
  );
};

export default Services;