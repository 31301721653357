import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const PageWrapper = styled.div`
  padding-top: 70px;
`;

const About: React.FC = () => {
  return (
    <PageWrapper>
      <h1>关于我们</h1>
      {/* 这里添加关于页面的具体内容 */}
    </PageWrapper>
  );
};

export default About;
