import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Input, Button, Form, Modal, message } from "antd";
import emailjs from '@emailjs/browser';

// 定义EmailJS响应类型
interface EmailJSResponseStatus {
  status: number;
  text: string;
}

const ContactSection = styled.section`
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #edf6f9 35%, #e8f4f8 100%);
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  color: #023e8a;
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #00b4d8, #48cae4);
    border-radius: 2px;
  }
`;

const SectionSubtitle = styled.p`
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #00b4d8, #48cae4);
  border: none;
  height: 48px;
  padding: 0 40px;
  font-size: 1.1rem;
  border-radius: 24px;
  box-shadow: 0 4px 15px rgba(0, 180, 216, 0.2);
  
  &:hover {
    background: linear-gradient(90deg, #0077b6, #00b4d8);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 180, 216, 0.3);
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 0;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
  
  @media (max-width: 768px) {
    .ant-modal-content {
      padding: 15px;
    }
    
    .ant-modal-body {
      padding: 15px;
    }
    
    width: 90% !important;
    margin: 0 auto;
  }
`;

const Contact: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // 重试函数
  const retryEmailSend = async (
    serviceId: string,
    templateId: string,
    templateParams: Record<string, unknown>,
    publicKey: string,
    retries: number = 3,
    delay: number = 1000
  ): Promise<EmailJSResponseStatus> => {
    try {
      return await emailjs.send(serviceId, templateId, templateParams, publicKey);
    } catch (error) {
      if (retries <= 1) throw error;
      console.log(`重试剩余次数: ${retries - 1}`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryEmailSend(serviceId, templateId, templateParams, publicKey, retries - 1, delay);
    }
  };

  const onFinish = (values: any) => {
    setLoading(true);

    // 立即给用户反馈
    message.success('提交成功！我们会尽快与您联系。');
    form.resetFields();
    handleCancel();
    setLoading(false);

    // 后台异步发送邮件
    const templateParams = {
      from_name: values.name,
      reply_to: values.email,
      phone_number: values.phone,
      message: values.message,
      to_name: '曲和科技',
    };

    // 使用 Promise 在后台处理
    retryEmailSend(
      'service_sx9z9q1',
      'template_bqxp0uc',
      templateParams,
      'ZB8bhDgJCErGiZ4eF'
    )
      .catch((error) => {
        console.error('EmailJS Error:', error);
        // 可以在这里添加错误处理逻辑，比如记录日志
      });
  };

  return (
    <ContactSection id="contact">
      <Container>
        <SectionTitle>联系我们</SectionTitle>
        <SectionSubtitle>
          请留下您的联系信息和诉求，我们会尽快与您联系
        </SectionSubtitle>
        <StyledButton type="primary" onClick={showModal}>
          填写需求
        </StyledButton>

        <StyledModal
          title="需求表单"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={600}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="姓名"
              rules={[{ required: true, message: '请输入您的姓名' }]}
            >
              <Input placeholder="请输入您的姓名" />
            </Form.Item>
            
            <Form.Item
              name="phone"
              label="电话"
              rules={[
                { required: true, message: '请输入您的电话' },
                { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码' }
              ]}
            >
              <Input placeholder="请输入您的电话" />
            </Form.Item>

            <Form.Item
              name="email"
              label="邮箱"
              rules={[
                { required: true, message: '请输入您的邮箱' },
                { type: 'email', message: '请输入有效的邮箱地址' }
              ]}
            >
              <Input placeholder="请输入您的邮箱" />
            </Form.Item>

            <Form.Item
              name="message"
              label="诉求"
              rules={[{ required: true, message: '请输入您的诉求' }]}
            >
              <Input.TextArea rows={4} placeholder="请输入您的诉求" />
            </Form.Item>

            <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
              <Button onClick={handleCancel} style={{ marginRight: 16 }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
            </Form.Item>
          </Form>
        </StyledModal>
      </Container>
    </ContactSection>
  );
};

export default Contact;
