import React from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import type { CarouselRef } from 'antd/lib/carousel';
import { motion } from "framer-motion";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const BannerWrapper = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const StyledCarousel = styled(Carousel)`
  height: 100vh;

  .slick-dots-bottom {
    bottom: 60px;
    
    li {
      button {
        background: rgba(255, 255, 255, 0.4);
        height: 4px;
        width: 30px;
        border-radius: 2px;
        transition: all 0.3s ease;
      }
      
      &.slick-active button {
        background: #fff;
        width: 50px;
      }
    }
  }
`;

const Slide = styled.div<{ bgImage: string }>`
  height: 100vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.2)
  ), url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  display: flex !important;
  align-items: center;
  padding: 0 5%;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
`;

const ButtonGroup = styled(motion.div)`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const PrimaryButton = styled(Button)`
  height: 50px;
  padding: 0 35px;
  font-size: 1.1rem;
  border-radius: 25px;
  background: #00b4d8;
  border: none;
  text-shadow: none;
  font-weight: 500;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background: #0077b6;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
`;

const SecondaryButton = styled(Button)`
  height: 50px;
  padding: 0 35px;
  font-size: 1.1rem;
  border-radius: 25px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.8);
  color: #fff;
  text-shadow: none;
  font-weight: 500;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    border-color: #fff;
    color: #fff;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  color: #0077b6;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background: #00b4d8;
    color: #fff;
    transform: translateY(-50%) scale(1.1);
  }
`;

const PrevArrow = styled(ArrowButton)`
  left: 20px;
`;

const NextArrow = styled(ArrowButton)`
  right: 20px;
`;

const slides = [
  {
    image: "http://oss.opsoul.com/slide1.jpeg-jpg",
    title: "创新技术解决方案",
    subtitle: "为企业提供专业的技术服务，助力数字化转型"
  },
  {
    image: "http://oss.opsoul.com/slide2.jpeg-jpg",
    title: "智能化系统开发",
    subtitle: "打造智能化、自动化的业务系统，提升运营效率"
  },
  {
    image: "http://oss.opsoul.com/slide3.jpeg-jpg",
    title: "全方位技术支持",
    subtitle: "提供全面的技术支持服务，确保系统稳定运行"
  }
];

const Banner: React.FC = () => {
  const carouselRef = React.useRef<CarouselRef>(null);
  const navigate = useNavigate();

  const handlePrev = () => {
    carouselRef.current?.prev();
  };

  const handleNext = () => {
    carouselRef.current?.next();
  };

  const handleContactClick = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <BannerWrapper>
      <PrevArrow onClick={handlePrev}>
        <LeftOutlined />
      </PrevArrow>
      <NextArrow onClick={handleNext}>
        <RightOutlined />
      </NextArrow>
      <StyledCarousel 
        ref={carouselRef}
        autoplay 
        effect="fade" 
        autoplaySpeed={3000}
      >
        {slides.map((slide, index) => (
          <Slide key={index} bgImage={slide.image}>
            <Content>
              <Title
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                {slide.title}
              </Title>
              <Subtitle
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                {slide.subtitle}
              </Subtitle>
              <ButtonGroup
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <PrimaryButton type="primary" size="large" onClick={handleContactClick}>
                  联系我们
                </PrimaryButton>
              </ButtonGroup>
            </Content>
          </Slide>
        ))}
      </StyledCarousel>
    </BannerWrapper>
  );
};

export default Banner;
