import React from "react";
import styled from "styled-components";
import { Row, Col, Card } from "antd";
import { motion } from "framer-motion";

const CasesSection = styled.section`
  padding: 100px 5%;
  background: #fff;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(0, 180, 216, 0.2), transparent);
  }
`;

const SectionTitle = styled(motion.h2)`
  text-align: center;
  font-size: 2.8rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled(motion.p)`
  text-align: center;
  font-size: 1.2rem;
  color: #0077b6;
  margin-bottom: 4rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 15px;
  }
`;

const CaseCard = styled(motion(Card))`
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 180, 216, 0.1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

  .ant-card-cover {
    overflow: hidden;
    
    img {
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform;
    }
  }

  .ant-card-body {
    padding: 20px;
  }

  h3 {
    color: #023e8a;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    transition: color 0.3s ease;
  }

  p {
    color: #0077b6;
    font-size: 0.9rem;
    margin: 0;
    transition: color 0.3s ease;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 180, 216, 0.15);

    .ant-card-cover img {
      transform: scale(1.05);
    }
  }
`;

const cases = [
  {
    image: "http://oss.opsoul.com/case1.jpeg-jpg",
    title: "企业官网开发",
    description: "为某大型制造企业打造的现代化官方网站"
  },
  {
    image: "http://oss.opsoul.com/case2.jpeg-jpg",
    title: "移动应用开发",
    description: "智能家居控制系统 App 开发"
  },
  {
    image: "http://oss.opsoul.com/case3.jpeg-jpg",
    title: "数据分析平台",
    description: "企业级数据可视化解决方案"
  },
  {
    image: "http://oss.opsoul.com/case4.jpeg-jpg",
    title: "电商系统开发",
    description: "全渠道电商解决方案"
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.3,
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 30
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.8,
      bounce: 0.3,
    }
  }
};

const Cases: React.FC = () => {
  return (
    <CasesSection>
      <SectionTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.5 }}
      >
        成功案例
      </SectionTitle>
      <SectionSubtitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        我们为众多企业提供了专业的技术解决方案
      </SectionSubtitle>
      
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <Row gutter={[32, 32]}>
          {cases.map((item, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <CaseCard
                variants={itemVariants}
                cover={<img alt={item.title} src={item.image} />}
              >
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </CaseCard>
            </Col>
          ))}
        </Row>
      </motion.div>
    </CasesSection>
  );
};

export default Cases;
