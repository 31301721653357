import React from "react";
import styled from "styled-components";
import Banner from "./components/Banner";
import Services from "./components/Services";
import Cases from "./components/Cases";
import Contact from "./components/Contact";

const HomePage = styled.div`
  width: 100%;
`;

const Home: React.FC = () => {
  return (
    <HomePage>
      <Banner />
      <Services />
      <Cases />
      <Contact />
    </HomePage>
  );
};

export default Home;