import React from "react";
import styled from "styled-components";
import { 
  PhoneOutlined, 
  MailOutlined, 
  EnvironmentOutlined,
  GithubOutlined,
  WechatOutlined,
  QqOutlined
} from "@ant-design/icons";

const FooterWrapper = styled.footer`
  background: linear-gradient(
    135deg,
    #f8f9fa 0%,
    #edf6f9 35%,
    #e8f4f8 100%
  );
  color: #2F4F4F;
  padding: 60px 0 30px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const FooterSection = styled.div`
  h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 10px;
    color: #2F4F4F;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 2px;
      background: #5F9EA0;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 15px;
  
  p {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    
    .anticon {
      color: #5F9EA0;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  
  a {
    color: #2F4F4F;
    font-size: 1.5rem;
    transition: color 0.3s;
    
    &:hover {
      color: #5F9EA0;
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding: 20px 15px;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const QRCodePopup = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: none;
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background: white;
    transform: rotate(45deg);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const SocialLink = styled.div`
  position: relative;
  cursor: pointer;
  color: #2F4F4F;
  font-size: 1.5rem;
  transition: color 0.3s;
  
  &:hover {
    color: #5F9EA0;
  }
  
  &:hover ${QRCodePopup} {
    display: block;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterContent>
          <FooterSection>
            <h3>联系方式</h3>
            <ContactInfo>
              <p>
                <PhoneOutlined />
                18998363070
              </p>
              <p>
                <MailOutlined />
                clunt@vip.163.com
              </p>
              <p>
                <EnvironmentOutlined />
                广州市天河区天河北路179号
              </p>
            </ContactInfo>
          </FooterSection>
          
          <FooterSection>
            <h3>服务项目</h3>
            <ul>
              <li>云服务解决方案</li>
              <li>移动应用开发</li>
              <li>数字化转型</li>
              <li>技术咨询服务</li>
            </ul>
          </FooterSection>
          
          <FooterSection>
            <h3>关于我们</h3>
            <p>我们是一家专注于提供高质量软件开发服务的技术公司，致力于帮助企业实现数字化转型。</p>
            <SocialLinks>
              <SocialLink as="a" href="https://github.com/clunt-fun" target="_blank" rel="noopener noreferrer">
                <GithubOutlined />
              </SocialLink>
              <SocialLink>
                <WechatOutlined />
                <QRCodePopup>
                  <img src="https://oss.opsoul.com/wx_qrcode.jpg-jpg" alt="WeChat QR Code" />
                </QRCodePopup>
              </SocialLink>
              <SocialLink>
                <QqOutlined />
                <QRCodePopup>
                  <img src="https://oss.opsoul.com/qq_qrcode.jpg-jpg" alt="QQ QR Code" />
                </QRCodePopup>
              </SocialLink>
            </SocialLinks>
          </FooterSection>
        </FooterContent>
        
        <Copyright>
          <p>© 2024 广州曲和网络科技有限公司. All rights reserved.</p>
          <p>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
              粤ICP备2021044349号
            </a>
          </p>
        </Copyright>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
