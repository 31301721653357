import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const PageWrapper = styled.div`
  padding-top: 70px;
`;

const Contact: React.FC = () => {
  return (
    <PageWrapper>
      <h1>联系我们</h1>
      {/* 这里添加联系页面的具体内容 */}
    </PageWrapper>
  );
};

export default Contact;
