import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import { CodeOutlined } from "@ant-design/icons";

const HeaderWrapper = styled.header<{ isScrolled: boolean }>`
  background: ${props => props.isScrolled ? 'rgba(255, 255, 255, 0.95)' : 'transparent'};
  box-shadow: ${props => props.isScrolled ? '0 2px 15px rgba(0, 180, 216, 0.1)' : 'none'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(10px)' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: ${props => props.isScrolled 
    ? '1px solid rgba(0, 180, 216, 0.1)' 
    : '1px solid rgba(255, 255, 255, 0.15)'};
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      ${props => props.isScrolled ? 'rgba(0, 180, 216, 0.2)' : 'rgba(255, 255, 255, 0.3)'},
      transparent
    );
    opacity: 0.8;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const LogoLink = styled(Link)<{ isScrolled: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding: 8px 15px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${props => props.isScrolled ? 'rgba(0, 180, 216, 0.15)' : 'rgba(255, 255, 255, 0.15)'};
    border-radius: 8px;
    transform: translate(3px, 3px);
    transition: all 0.3s ease;
  }

  &:hover {
    transform: translateY(-1px);
    
    &::before {
      transform: translate(5px, 5px);
    }
  }
`;

const LogoIcon = styled.svg<{ isScrolled: boolean }>`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  fill: none;
  stroke: ${props => props.isScrolled ? '#00b4d8' : '#fff'};
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease;
  
  path {
    transition: all 0.3s ease;
    
    &:first-child {
      opacity: 0.8;
    }
    
    &:last-child {
      opacity: 0.6;
    }
  }
  
  &:hover path {
    opacity: 1;
    stroke-width: 2;
  }
`;

const LogoText = styled.span<{ isScrolled: boolean }>`
  color: ${props => props.isScrolled ? '#00b4d8' : '#fff'};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <Nav>
        <LogoLink to="/" isScrolled={isScrolled}>
          <LogoIcon 
            viewBox="0 0 24 24" 
            isScrolled={isScrolled}
          >
            <path d="M7 7H17V17H7V7Z" />
            <path d="M5 5L19 5L19 19L5 19L5 5Z" />
          </LogoIcon>
          <LogoText isScrolled={isScrolled}>广州曲和网络科技有限公司</LogoText>
        </LogoLink>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;