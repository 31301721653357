import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import GlobalStyles from "./styles/GlobalStyles";
import MainLayout from "./components/MainLayout";
import AppRoutes from "./routes";

// 定义主题色
const theme = {
  colorPrimary: '#00b4d8', // 明亮的天蓝色
  colorSecondary: '#90e0ef', // 浅天蓝色
  colorAccent: '#48cae4', // 中等天蓝色
  colorText: '#023e8a', // 深蓝色文字
  colorTextSecondary: '#0077b6', // 次要文字颜色
  colorBackground: '#caf0f8', // 超浅天蓝色背景
};

function App() {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: theme.colorPrimary,
          colorInfo: theme.colorPrimary,
          colorLink: theme.colorPrimary,
          colorLinkHover: theme.colorAccent,
        }
      }}
    >
      <GlobalStyles />
      <BrowserRouter>
        <MainLayout>
          <AppRoutes />
        </MainLayout>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
