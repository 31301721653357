import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }

  p {
    color: #666;
    line-height: 1.6;
  }

  .ant-card {
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    }
  }

  .ant-btn {
    border-radius: 4px;
    &.ant-btn-primary {
      background: #1890ff;
      &:hover {
        background: #40a9ff;
      }
    }
  }

  .ant-input, .ant-input-textarea {
    border-radius: 4px;
    &:hover, &:focus {
      border-color: #40a9ff;
    }
  }

  html {
    scroll-padding-top: 80px;
  }
`;

export default GlobalStyles; 