import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const PageWrapper = styled.div`
  padding-top: 70px;
  min-height: calc(100vh - 70px);
  background: linear-gradient(135deg, #f0f9ff, #e0f7fa);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const PageTitle = styled(motion.h1)`
  color: #023e8a;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #00b4d8, #48cae4);
    border-radius: 2px;
  }
`;

const Cases: React.FC = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <PageTitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          成功案例
        </PageTitle>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Cases; 